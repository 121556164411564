// stylelint-disable meowtec/no-px
// stylelint-disable scale-unlimited/declaration-strict-value
// stylelint-disable scss/selector-no-redundant-nesting-selector
.#{$rt-namespace}__close-button {
	align-self: flex-start;
	padding: 0;
	border: none;
	background: transparent;
	color: #fff;
	cursor: pointer;
	opacity: 0.7;
	outline: none;
	transition: 0.3s ease;

	&--light {
		color: #000;
		opacity: 0.3;
	}

	& > svg {
		width: 14px;
		height: 16px;
		fill: currentColor;
	}

	&:hover,
	&:focus {
		opacity: 1;
	}
}
// stylelint-enable meowtec/no-px
// stylelint-enable scale-unlimited/declaration-strict-value
// stylelint-enable scss/selector-no-redundant-nesting-selector
